import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SIGNIN_MUTATION, SIGNUP_MUTATION } from "./auth.api";
import { GET_CURRENT_USER } from "../user/userQueries";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Input from "../common/Input";

export interface SignUpInput {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
}

const schema = yup
    .object({
        name: yup
            .string()
            .min(4, "Name must be at least 4 characters")
            .required(),
        email: yup.string().email("Please enter a valid email").required(),
        password: yup
            .string()
            .required()
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&\.\?\$\^\*])(?=.{8,})/,
                () => {
                    return (
                        <div>
                            <ul className="list-disc list-inside">
                                Password must contain
                                <li>8 characters or more</li>
                                <li>1 uppercase letter</li>
                                <li>1 lowercase letter</li>
                                <li>1 number</li>
                                <li>
                                    1 special character
                                    {`!@#%&.?$^*`}
                                </li>
                            </ul>
                        </div>
                    );
                }
            ),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Password confirmation is required"),
    })
    .required();

export default function SignUp() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        getValues,
        reset,
        formState: { errors },
    } = useForm<SignUpInput>({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });
    const [signup, { loading, error }] = useMutation(SIGNUP_MUTATION, {
        variables: getValues(),
    });
    const [signin, { loading: signinLoading, error: singinError }] =
        useMutation(SIGNIN_MUTATION, {
            variables: {
                email: getValues().email,
                password: getValues().password,
            },
            refetchQueries: [{ query: GET_CURRENT_USER }],
        });

    return (
        <div className=" px-2 sm:px-6 lg:px-8 flex justify-center content-center">
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <Link to="/">
                            <svg
                                className="mx-auto h-12 w-auto text-green-600"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 10V3L4 14h7v7l9-11h-7z"
                                ></path>
                            </svg>
                        </Link>
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight">
                            Register for an account
                        </h2>
                    </div>
                    <form
                        method="POST"
                        onSubmit={handleSubmit(async (inputs) => {
                            await signup();
                            await signin();

                            reset();
                            return navigate("/");
                        })}
                    >
                        <fieldset disabled={loading || signinLoading}>
                            <Input
                                fieldName="name"
                                label="Name"
                                register={register}
                                errors={errors}
                            />
                            <Input
                                fieldName="email"
                                label="Email"
                                register={register}
                                errors={errors}
                            />
                            <Input
                                fieldName="password"
                                label="Password"
                                type="password"
                                register={register}
                                errors={errors}
                            />
                            <Input
                                fieldName="confirmPassword"
                                label="Confirm password"
                                type="password"
                                register={register}
                                errors={errors}
                            />
                            <button
                                type="submit"
                                className="btn-primary group relative w-full"
                            >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <svg
                                        className="h-5 w-5 text-green-700 group-hover:text-green-600"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </span>
                                Register
                            </button>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    );
}
