import { gql } from "@apollo/client";
import { Ingredient } from "../meals/MealPlanList";

export function getIngredientCreateInput(ingredient: Ingredient) {
    return {
        ...ingredient,
        food: {
            connect: {
                id: ingredient.food.id,
            },
        },
        recipe: ingredient.recipe?.id
            ? {
                  connect: {
                      id: ingredient.recipe.id,
                  },
              }
            : undefined,
    };
}
export function getIngredientUpdateInput({
    description,
    food,
    quantity,
    quantityType,
    recipe,
}: Ingredient) {
    return {
        description,
        quantity,
        quantityType,
        food: {
            connect: {
                id: food.id,
            },
        },
        recipe: recipe?.id
            ? {
                  connect: {
                      id: recipe.id,
                  },
              }
            : undefined,
    };
}

export const CREATE_INGREDIENT = gql`
    mutation CREATE_INGREDIENT($ingredient: IngredientCreateInput!) {
        createIngredient(data: $ingredient) {
            id
        }
    }
`;
export const UPDATE_INGREDIENT = gql`
    mutation UPDATE_INGREDIENT($id: ID!, $ingredient: IngredientUpdateInput!) {
        updateIngredient(where: { id: $id }, data: $ingredient) {
            id
        }
    }
`;
export const DELETE_INGREDIENT = gql`
    mutation DELETE_INGREDIENT($id: ID!) {
        deleteIngredient(where: { id: $id }) {
            id
        }
    }
`;
export const DELETE_INGREDIENTS = gql`
    mutation DELETE_INGREDIENTS($where: IngredientWhereUniqueInput!) {
        deleteIngredient(where: $where) {
            id
        }
    }
`;
