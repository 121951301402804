import { useState } from "react";

export interface IOptionsMenuOption {
    label: string;
    onClick: (...args: any) => void;
    hightlight?: boolean;
}

export interface IOptionsMenuProps {
    options: IOptionsMenuOption[];
    className?: string;
}

const OptionsMenu: React.FC<IOptionsMenuProps> = ({
    options,
    className = "",
}) => {
    const [toggleOptionsMenu, setToggleOptionsMenu] = useState(false);
    const handleOptionClick =
        (onClick: IOptionsMenuOption["onClick"]) => () => {
            setToggleOptionsMenu(false);
            onClick();
        };
    return (
        <div
            className={`${className} absolute flex flex-col h-full right-[0.5rem] left-auto text-right z-10`}
        >
            <button
                id="optionsButton"
                className={`${
                    options.length > 0 ? "" : "hidden"
                } w-fit self-end inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-2 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-0.5`}
                onClick={() => setToggleOptionsMenu(!toggleOptionsMenu)}
            >
                <span className="sr-only">Open options</span>
                <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                </svg>
            </button>
            <div
                id="options"
                className={`${
                    toggleOptionsMenu ? "" : "hidden"
                } z-10 w-44 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700`}
            >
                <ul aria-labelledby="optionsButton">
                    {options.map(({ label, onClick, hightlight }) => {
                        return (
                            <li key={label}>
                                <button
                                    className={`${
                                        hightlight
                                            ? "text-red-500"
                                            : "text-gray-700 dark:text-gray-200"
                                    } block w-full text-right py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white`}
                                    onClick={handleOptionClick(onClick)}
                                >
                                    {label}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default OptionsMenu;
