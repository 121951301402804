import { DocumentNode, useApolloClient } from "@apollo/client";
import _ from "lodash";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import useForm from "../../utils/useForm";
import CardWithImage from "./CardWithImage";
import Modal from "./Modal";
import Search from "./Search";

export interface ISearchModalProps {
    query: DocumentNode;
    queryName: string;
    onClose: () => void;
    onClick: (result: SearchResult | any) => void;
    header?: string;
}
export interface SearchResult {
    id: string;
    name: string;
    image?: string;
}
const SearchModal: React.FC<ISearchModalProps> = ({
    query,
    queryName,
    onClick,
    onClose,
    header = "Search",
}) => {
    return (
        <Modal>
            <div className="flex justify-between items-start p-4 pl-0 mb-4 rounded-t border-b dark:border-gray-600">
                <h4 className="text-2xl font-bold text-white">{header}</h4>
                <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={onClose}
                >
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <Search query={query} queryName={queryName} onClick={onClick} />
        </Modal>
    );
};

export default SearchModal;
