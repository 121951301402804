import {
    format,
    isBefore,
    isAfter,
    isEqual,
    subMonths,
    addMonths,
    addDays,
    eachDayOfInterval,
    addWeeks,
    subWeeks,
    endOfWeek,
    startOfWeek,
} from "date-fns";

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

export function getMonth(date: Date) {
    return months[date.getMonth()];
}

export function getDay(date: Date) {
    return days[date.getDay()];
}
export const formatDisplayDate = (date: Date) =>
    format(new Date(date), "EEE dd MMM");
export const formatDate = (date: Date) => format(new Date(date), "yyyy-MM-dd");
export const isOnOrBetweenDates = (
    startDate: Date,
    endDate: Date,
    dateToCompare = new Date()
) => {
    const formattedDateToCompare = new Date(formatDate(dateToCompare));
    const formattedStartDate = new Date(formatDate(startDate));
    const formattedEndDate = new Date(formatDate(endDate));
    const isEndDateAfterToday = isAfter(
        formattedEndDate,
        formattedDateToCompare
    );
    const isEndDateToday = isEqual(formattedEndDate, formattedDateToCompare);
    const isStartDateBeforeToday = isBefore(
        formattedStartDate,
        formattedDateToCompare
    );
    const isStartDateToday = isEqual(
        formattedStartDate,
        formattedDateToCompare
    );

    return (
        isStartDateToday ||
        isEndDateToday ||
        (isStartDateBeforeToday && isEndDateAfterToday)
    );
};
export function getPreviousCalendarDays(end = new Date(), range = 2) {
    const start = subWeeks(end, range);

    return eachDayOfInterval({ start, end });
}

export function getNextCalendarDays(start = new Date(), range = 2) {
    const end = addWeeks(start, range);

    return eachDayOfInterval({ start, end });
}
export function getCalendarDays(middleDate = new Date(), range = 2) {
    //   const start = subWeeks(middleDate, range);
    //   const end = addWeeks(middleDate, range);

    //   return eachDayOfInterval({ start, end });
    return getPreviousCalendarDays(middleDate, range).concat(
        getNextCalendarDays(addDays(middleDate, 1), range)
    );
}

export function getDaysOfWeek(date = new Date(), weekOffset = 0) {
    const baseDate = addWeeks(date, weekOffset);
    const start = startOfWeek(baseDate, { weekStartsOn: 1 });
    const end = endOfWeek(baseDate, { weekStartsOn: 1 });

    return eachDayOfInterval({ start, end });
}
