import _ from "lodash";
import { ChangeEvent, useState } from "react";

export interface Form<Type> {
    inputs: Type;
    handleInputChange: (
        event: ChangeEvent,
        path?: string
    ) => string | number | boolean | any;
    setInputValue: (
        name: string,
        value: string | number | boolean | any
    ) => string | number | boolean | any;
    resetForm: () => void;
    clearForm: () => void;
}

export default function useForm(initial = {}): Form<any> {
    const [inputs, setInputs] = useState(_.cloneDeep(initial));
    //   const initialValues = Object.values(initial).join('');

    //   useEffect(() => {
    //     setInputs(initial);
    //   }, [initialValues]);

    function handleInputChange(
        e: ChangeEvent<any>,
        path?: string
    ): string | number | boolean | any {
        let { value, name, type } = e.target;
        if (type === "number") {
            value = parseInt(value);
        }
        if (type === "file") {
            [value] = e.target.files;
        }
        const newInputs = _.set({ ...inputs }, path || name, value);
        setInputs(newInputs);

        return value;
    }

    function setInputValue(
        name: string,
        value: string | number | boolean | any
    ): string | number | boolean | any {
        const newInputs = _.set({ ...inputs }, name, value);
        setInputs(newInputs);

        return value;
    }

    function resetForm() {
        setInputs(initial);
    }

    function clearForm() {
        const blankState = Object.fromEntries(
            Object.entries(inputs).map(([key, value]) => [key, ""])
        );
        setInputs(blankState);
    }

    return {
        inputs,
        handleInputChange,
        setInputValue,
        resetForm,
        clearForm,
    };
}
