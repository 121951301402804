import { DocumentNode, useApolloClient } from "@apollo/client";
import _ from "lodash";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import useForm from "../../utils/useForm";
import CardWithImage from "./CardWithImage";

export interface ISearchProps {
    query: DocumentNode;
    queryName: string;
    pageSize?: number;
    onClick: (result: SearchResult) => void;
    header?: string;
}
export interface SearchResult {
    id: string;
    name: string;
    photo?: any;
}
const Search: React.FC<ISearchProps> = ({
    query,
    queryName,
    pageSize: take = 20,
    onClick,
}) => {
    const { inputs, handleInputChange } = useForm({ search: "" });
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [skip, setSkip] = useState<number>(0);
    const debouncedSearch = useRef<_.DebouncedFunc<() => any> | null>(null);
    const client = useApolloClient();
    const handleSearch = useCallback(
        (client: any, search: string) => {
            return client
                .query({
                    query,
                    variables: { search, take, skip },
                })
                .then((res: any) => res.data[queryName]);
        },
        [query, queryName, skip, take]
    );

    useEffect(() => {
        (async () => {
            const recipes = await handleSearch(client, "");
            setSearchResults(recipes);
        })();
    }, []);

    // Manually query apollo client
    const handleSearchOnChange = (client: any) => (e: ChangeEvent<any>) => {
        if (debouncedSearch.current) {
            debouncedSearch.current.cancel();
        }
        const searchValue = handleInputChange(e);
        debouncedSearch.current = _.debounce(async () => {
            if (!searchValue) return;
            const recipes = await handleSearch(client, searchValue);
            setSearchResults(recipes);
        }, 500);
        debouncedSearch.current();
    };
    const handleOnClick = (result: SearchResult) => () => onClick(result);

    return (
        <>
            <form className="pb-2">
                <label
                    htmlFor="default-search"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
                >
                    Search
                </label>
                <div className="relative">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            ></path>
                        </svg>
                    </div>
                    <input
                        type="search"
                        id="default-search"
                        className="ip-base pl-10"
                        placeholder="Search..."
                        required
                        value={inputs.value}
                        onChange={handleSearchOnChange(client)}
                    />
                </div>
            </form>
            <div className="flex flex-col md:flex-wrap max-w-full h-auto md:flex-row md:justify-between overflow-y-scroll">
                {searchResults.map((result) => {
                    return (
                        <div
                            key={result.id}
                            className="pb-2 shrink-0 grow-1 w-[95%] h-100 md:w-1/2"
                        >
                            <CardWithImage
                                title={result.name}
                                image={
                                    result?.photo?.image?.publicUrlTransformed
                                }
                                onClick={handleOnClick(result)}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Search;
