import { Link, Outlet } from "react-router-dom";
import { Header } from "../components/header/Header";

function App() {
    return (
        <div className="mb-8">
            <header>
                <Header />
            </header>
            <main className="mx-auto max-w-7xl px-4 mt-4">
                <Outlet />
            </main>
        </div>
    );
}

export default App;
