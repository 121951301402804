import { useMutation } from "@apollo/client";
import { useState } from "react";
import Add from "../../icons/Add";
import useForm from "../../utils/useForm";
import ButtonGroup from "../common/ButtonGroup";
import SearchModal, { SearchResult } from "../common/SearchModal";
import { Ingredient, Recipe } from "../meals/MealPlanList";
import {
    CREATE_INGREDIENT,
    getIngredientCreateInput,
    getIngredientUpdateInput,
    UPDATE_INGREDIENT,
} from "./ingredients.api";
import { SEARCH_FOOD_QUERY } from "./recipe.query";

interface IIngredientFormProps {
    ingredient?: Ingredient;
    onSubmit: (ingredient?: Ingredient) => void;
    onCompleted?: (ingredient: Ingredient) => void;
    onError?: (error: any) => void;
    onCancel?: () => void;
    onInputChange?: (value: any) => void;
}
const quantityTypeOptions = [
    {
        label: "Cloves",
        value: "CLOVES",
    },
    {
        label: "Cups",
        value: "CUPS",
    },
    {
        label: "Grams",
        value: "GRAMS",
    },
    {
        label: "Jars",
        value: "JARS",
    },
    {
        label: "Leaves",
        value: "LEAVES",
    },
    {
        label: "Liters",
        value: "LITERS",
    },
    {
        label: "Milliliters",
        value: "MILLILITERS",
    },
    {
        label: "Ounces",
        value: "OUNCES",
    },
    {
        label: "Pounds",
        value: "POUNDS",
    },
    {
        label: "Sprigs",
        value: "SPRIGS",
    },
    {
        label: "Tablespoons",
        value: "TABLESPOONS",
    },
    {
        label: "Teaspoons",
        value: "TEASPOONS",
    },
    {
        label: "Tins",
        value: "TINS",
    },
    {
        label: "Whole",
        value: "WHOLE",
    },
];
const IngredientForm: React.FC<IIngredientFormProps> = ({
    ingredient = {},
    onSubmit,
    onCompleted,
    onError,
    onCancel,
    onInputChange,
}) => {
    const { inputs, handleInputChange, setInputValue } = useForm({
        quantityType: quantityTypeOptions[0].value,
        ...ingredient,
    });
    const [openSearchFoodModal, setOpenSearchFoodModal] = useState(false);
    const [createIngredient, { error, loading }] = useMutation(
        CREATE_INGREDIENT,
        {
            optimisticResponse: {
                createIngredient: {
                    ...inputs,
                    id: "temp",
                    __typename: "Ingredient",
                },
            },
            onCompleted: ({ createIngredient }) =>
                onCompleted?.(createIngredient),
            onError: (error) => {
                onError?.(error);
            },
        }
    );
    const [updateIngredient] = useMutation(UPDATE_INGREDIENT, {
        optimisticResponse: {
            updateIngredient: {
                ...inputs,
            },
        },
        onCompleted: ({ updateIngredient }) => onCompleted?.(updateIngredient),
        onError: (error) => {
            onError?.(error);
        },
    });
    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    try {
                        onSubmit(inputs);
                        if (inputs.id) {
                            const updateIngredientInput =
                                getIngredientUpdateInput(inputs);
                            updateIngredient({
                                variables: {
                                    id: inputs.id,
                                    ingredient: updateIngredientInput,
                                },
                            });
                        } else {
                            const createIngredientInput =
                                getIngredientCreateInput(inputs);
                            createIngredient({
                                variables: {
                                    ingredient: createIngredientInput,
                                },
                            });
                        }
                    } catch (ex) {
                        console.error(`Failed to save ingredient: ${ex}`);
                    }
                }}
            >
                <fieldset className="">
                    <label htmlFor="description" className="ip-label">
                        Description
                    </label>
                    <input
                        id="description"
                        name="description"
                        type="text"
                        value={inputs?.description}
                        className="ip-base"
                        placeholder="Finely chopped..."
                        onChange={(e) => handleInputChange(e, `description`)}
                    />
                    <label htmlFor="food" className="ip-label">
                        Food
                    </label>
                    <input
                        id="food"
                        name="food"
                        type="text"
                        required
                        value={inputs?.food?.name}
                        className="ip-base"
                        placeholder="Garlic"
                        onClick={() => {
                            // setSelectedIngredientIndex(index);
                            setOpenSearchFoodModal(true);
                        }}
                        readOnly
                    />
                    <label htmlFor="quantity" className="ip-label">
                        Quantity
                    </label>
                    <input
                        id="quantity"
                        name="quantity"
                        type="number"
                        required
                        value={inputs?.quantity}
                        className="ip-base"
                        placeholder="Finely chopped..."
                        onChange={(e) => handleInputChange(e, `quantity`)}
                    />
                    <ButtonGroup
                        className="mt-4"
                        buttons={quantityTypeOptions.map(
                            ({ label, value }) => ({
                                label,
                                isSelected: inputs.quantityType === value,
                                onClick: () =>
                                    setInputValue("quantityType", value),
                            })
                        )}
                    />
                    <div className="flex justify-end m-4">
                        <button
                            type="button"
                            className="btn-secondary"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button className="btn-primary ml-4" type="submit">
                            Save ingredient
                        </button>
                    </div>
                </fieldset>
            </form>
            {!openSearchFoodModal ? null : (
                <SearchModal
                    query={SEARCH_FOOD_QUERY}
                    queryName={"foods"}
                    onClose={() => setOpenSearchFoodModal(false)}
                    onClick={(food) => {
                        setInputValue(`food`, food);
                        setOpenSearchFoodModal(false);
                    }}
                />
            )}
        </>
    );
};

export default IngredientForm;
