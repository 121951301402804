import { IPropsWithChildrenAndClassName } from "../../types/IPropsWithChildrenAndClassName";

export interface IButtonGroupProps extends IPropsWithChildrenAndClassName {
    id?: string;
    buttons: {
        label: string;
        onClick: () => void;
        isSelected: boolean;
    }[];
}
const ButtonGroup: React.FC<IButtonGroupProps> = ({
    id,
    buttons,
    className,
}) => {
    return (
        <div
            id={id}
            className={`${className} w-inline-flex rounded-md shadow-sm flex-wrap ${
                buttons.length > 3 ? "justify-between" : ""
            }`}
            role="group"
        >
            {buttons.map(({ label, onClick, isSelected }, index) => {
                let className = `${
                    isSelected
                        ? "bg-green-500"
                        : "bg-transparent hover:bg-gray-900 dark:hover:bg-gray-700"
                } w-auto overflow-hidden whitespace-nowrap text-ellipsis px-4 py-2 text-sm font-medium text-gray-900 border-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:text-white dark:border-white dark:text-white dark:hover:text-white`;

                if (buttons.length > 3) {
                    className = className.concat(
                        " border rounded-md mb-2 w-1/3"
                    );
                } else if (index === 0) {
                    className = className.concat(" border rounded-l-md");
                } else if (index === buttons.length - 1) {
                    className = className.concat(" border rounded-r-md");
                } else {
                    className = className.concat(" border-t border-b");
                }
                return (
                    <button
                        key={label}
                        type="button"
                        className={className}
                        onClick={() => onClick()}
                    >
                        {label}
                    </button>
                );
            })}
        </div>
    );
};

export default ButtonGroup;
