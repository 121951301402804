import { useQuery } from "@apollo/client";
import { Navigate, useLocation } from "react-router-dom";
import { GET_CURRENT_USER } from "../user/userQueries";

export default function AuthenticatedRoute({
    children,
}: React.PropsWithChildren) {
    const { data, loading } = useQuery(GET_CURRENT_USER);
    const location = useLocation();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!data.authenticatedItem) {
        return <Navigate to="/signin" state={{ from: location.pathname }} />;
    }

    return <>{children}</>;
}
