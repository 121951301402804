import { useQuery } from "@apollo/client";
import { format } from "date-fns";
import { createContext, useState } from "react";
import ButtonGroup from "../components/common/ButtonGroup";
import Loader from "../components/common/Loader";
import { GET_USER_GROUPS } from "../components/group/group.api";
import { GET_MEALS } from "../components/meals/meal.queries";
import { Group, Meal, MealPlanList } from "../components/meals/MealPlanList";
import useUser from "../components/user/useUser";
import { getDaysOfWeek } from "../utils/DateUtil";

export const MealFilterContext = createContext<string | undefined>(undefined);

export default function MealPlans() {
    const { user } = useUser();
    const [weekOffset, setWeekOffset] = useState(0);
    const [mealFilerOption, setMealFilterOption] = useState<string | undefined>(
        undefined
    );
    const [dates, setDates] = useState(getDaysOfWeek(new Date(), weekOffset));
    const updateDates = (weekModifier: number) => {
        const newDates = getDaysOfWeek(new Date(), weekModifier);
        setWeekOffset(weekModifier);
        setDates(newDates);
    };
    const { loading, error, data, refetch } = useQuery<{ meals: Meal[] }>(
        GET_MEALS,
        {
            variables: {
                where: {
                    date: {
                        in: dates.map((date) => format(date, "yyyy-MM-dd")),
                    },
                    owner: { id: { equals: user.id } },
                },
            },
        }
    );
    const {
        loading: groupsLoading,
        error: groupsError,
        data: groupsData,
    } = useQuery<{ groups: Group[] }>(GET_USER_GROUPS, {
        variables: {
            id: user.id,
        },
    });
    if (loading || groupsLoading) return <Loader>Loading...</Loader>;
    if (error) return <div>{`Error! ${error.message}`}</div>;
    const personalFilterOption = {
        label: "Personal",
        value: undefined,
    };
    const groupFilterOptions =
        groupsData?.groups.map(({ id, name }) => ({
            label: name,
            value: id,
        })) || [];
    const mealFilerOptions = [personalFilterOption, ...groupFilterOptions];
    return (
        <div>
            {groupFilterOptions.length > 0 ? (
                <div className="mb-8">
                    <h2 className="text-2xl font-bold w-full mb-2">
                        Select group
                    </h2>
                    <ButtonGroup
                        id="mealFilterOptions"
                        buttons={mealFilerOptions.map(({ label, value }) => ({
                            label,
                            isSelected: mealFilerOption === value,
                            onClick: () => {
                                setMealFilterOption(value);
                                const filterKey = value ? "group" : "owner";
                                const variables = {
                                    where: {
                                        date: {
                                            in: dates.map((date) =>
                                                format(date, "yyyy-MM-dd")
                                            ),
                                        },
                                        [filterKey]: {
                                            id: {
                                                equals: value ?? user.id,
                                            },
                                        },
                                    },
                                };
                                refetch(variables);
                            },
                        }))}
                        className="mt-2 mb-2"
                    />
                </div>
            ) : null}
            <div className="flex justify-between mb-8">
                <>
                    <svg
                        onClick={() => updateDates(weekOffset - 1)}
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10 19l-7-7m0 0l7-7m-7 7h18"
                        ></path>
                    </svg>
                    {`${dates?.[0]?.toLocaleDateString()} / ${dates?.[
                        dates.length - 1
                    ]?.toLocaleDateString()}`}
                    <svg
                        onClick={() => updateDates(weekOffset + 1)}
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14 5l7 7m0 0l-7 7m7-7H3"
                        ></path>
                    </svg>
                </>
            </div>
            <MealFilterContext.Provider value={mealFilerOption}>
                <MealPlanList
                    dates={dates}
                    meals={data?.meals || []}
                    loading={loading || groupsLoading}
                />
            </MealFilterContext.Provider>
        </div>
    );
}
