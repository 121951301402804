import { gql } from "@apollo/client";
import _ from "lodash";
import { Recipe } from "../meals/MealPlanList";

export const GET_RECIPE = gql`
    query GET_RECIPE($id: ID!) {
        recipe(where: { id: $id }) {
            id
            name
            description
            instructions
            cookingTime
            prepTime
            servings
            difficulty
            photo {
                id
                altText
                image {
                    publicUrlTransformed
                }
            }
            ingredients {
                id
                name
                quantity
                quantityType
                description
                food {
                    id
                    name
                }
            }
            author {
                id
                name
            }
            contributors {
                id
                name
            }
            isPrivate
        }
    }
`;

export const SEARCH_RECIPES_QUERY = gql`
    query SEARCH_RECIPES_QUERY($search: String!, $skip: Int, $take: Int) {
        recipes(
            where: { name: { contains: $search, mode: insensitive } }
            orderBy: [{ name: asc }]
            skip: $skip
            take: $take
        ) {
            id
            name
            photo {
                id
                altText
                image {
                    publicUrlTransformed
                }
            }
        }
    }
`;

export function getRecipeCreateInput(recipe: Recipe) {
    const recipeCreateInput: any = _.cloneDeep(recipe);
    if (recipe.source) {
        recipeCreateInput.source = {
            create: {
                ...recipe.source,
            },
        };
    }
    recipeCreateInput.ingredients = {
        connect: recipe.ingredients.map(({ id }) => ({
            id,
        })),
    };
    recipeCreateInput.author = {
        connect: {
            id: recipe.author.id,
        },
    };
    // @ts-ignore
    if (recipe.image) {
        delete recipeCreateInput.image;
        recipeCreateInput.photo = {
            //@ts-ignore
            create: { image: recipe.image, altText: recipe.name },
        };
    }

    return recipeCreateInput;
}
export function getRecipeUpdateInput(
    updatedRecipe: Recipe,
    ingredientsToDelete: any
) {
    const recipeUpdateInput: any = _.cloneDeep(updatedRecipe);
    delete recipeUpdateInput.id;
    delete recipeUpdateInput.__typename;

    if (recipeUpdateInput.contributors.length === 0) {
        delete recipeUpdateInput.contributors;
    }
    if (updatedRecipe.source?.id) {
        recipeUpdateInput.source = {
            connect: {
                id: updatedRecipe.source.id,
            },
        };
    } else if (updatedRecipe.source) {
        recipeUpdateInput.source = {
            create: {
                ...updatedRecipe.source,
            },
        };
    }
    recipeUpdateInput.ingredients = {
        connect: updatedRecipe.ingredients.map(({ id }) => ({
            id,
        })),
        disconnect: ingredientsToDelete.map(({ id }: { id: string }) => ({
            id,
        })),
    };
    recipeUpdateInput.author = {
        connect: {
            id: updatedRecipe.author.id,
        },
    };
    if (recipeUpdateInput.image) {
        delete recipeUpdateInput.image;
        recipeUpdateInput.photo = {
            // @ts-ignore
            create: { image: updatedRecipe.image, altText: updatedRecipe.name },
        };
    } else {
        delete recipeUpdateInput.photo;
    }

    return recipeUpdateInput;
}
export const CREATE_RECIPE = gql`
    mutation CREATE_RECIPE($data: RecipeCreateInput!) {
        createRecipe(data: $data) {
            id
        }
    }
`;

export const UPDATE_RECIPE = gql`
    mutation UPDATE_RECIPE($id: ID!, $data: RecipeUpdateInput!) {
        updateRecipe(where: { id: $id }, data: $data) {
            id
            name
            description
            instructions
            cookingTime
            prepTime
            servings
            difficulty
            photo {
                id
                altText
                image {
                    publicUrlTransformed
                }
            }
            ingredients {
                id
                name
                quantity
                quantityType
                description
                food {
                    id
                    name
                }
            }
            author {
                id
                name
            }
            contributors {
                id
                name
            }
            isPrivate
        }
    }
`;
export const SEARCH_FOOD_QUERY = gql`
    query SEARCH_FOOD_QUERY($search: String!, $skip: Int, $take: Int) {
        foods(
            where: { name: { contains: $search, mode: insensitive } }
            orderBy: [{ name: asc }]
            skip: $skip
            take: $take
        ) {
            id
            name
        }
    }
`;
