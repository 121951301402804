import { useQuery } from "@apollo/client";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Outlet, redirect, useNavigate, useParams } from "react-router-dom";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import Add from "../../icons/Add";
import OptionsMenu from "../common/OptionsMenu";
import Search from "../common/Search";
import { Recipe as RecipeType } from "../meals/MealPlanList";
import { GET_RECIPE, SEARCH_RECIPES_QUERY } from "./recipe.query";
import RecipeForm from "./RecipeForm";

export const UpdateRecipe: React.FC = () => {
    const { recipeId } = useParams();
    const [showEditMealForm, setShowEditMealForm] = useState(false);
    const { data, loading } = useQuery<{ recipe: RecipeType }>(GET_RECIPE, {
        variables: {
            id: recipeId,
        },
    });
    const options = [
        {
            label: "Edit",
            onClick: () => {
                setShowEditMealForm(true);
            },
        },
        {
            label: "Delete",
            onClick: () => {},
            hightlight: true,
        },
    ];
    if (loading) return <div>loading...</div>;
    return (
        <div>
            {showEditMealForm ? (
                <RecipeForm
                    recipe={data?.recipe}
                    onCancel={() => setShowEditMealForm(false)}
                    onCompleted={() => setShowEditMealForm(false)}
                />
            ) : (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mb-8">
                        <div className="col-span-3">
                            {data?.recipe.photo ? (
                                <img
                                    className="w-full h-auto"
                                    src={
                                        data?.recipe.photo?.image
                                            ?.publicUrlTransformed
                                    }
                                    alt={data?.recipe.photo.altText}
                                />
                            ) : (
                                <div className="flex justify-center items-center w-full min-w-[12rem] h-full min-h-[12rem] bg-gray-300 rounded dark:bg-gray-700">
                                    <svg
                                        className="w-12 h-12 text-gray-200"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 640 512"
                                    >
                                        <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"></path>
                                    </svg>
                                </div>
                            )}
                        </div>
                        <div className="col-span-9">
                            <div className="relative">
                                <OptionsMenu
                                    key="options"
                                    options={options}
                                    className="mt-4"
                                />
                            </div>
                            <div className="mb-4">
                                <h2 className="text-3xl font-bold w-full mb-2">
                                    {data?.recipe.name}
                                </h2>
                                <p className="w-full mb-2 border-b-2">
                                    By {data?.recipe.author.name}
                                </p>
                                <p className="w-full">
                                    {data?.recipe.description}
                                </p>
                            </div>
                            <div className="flex flex-row justify-between max-w-lg">
                                <span>
                                    <svg
                                        className="w-6 h-6 text-green-600"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                        ></path>
                                    </svg>
                                    <h4 className="text-l font-bold">Prep</h4>
                                    <p>{data?.recipe.prepTime} minutes</p>
                                    <h4 className="text-l font-bold">
                                        Cooking
                                    </h4>
                                    <p>{data?.recipe.cookingTime} minutes</p>
                                </span>
                                <span>
                                    <svg
                                        className="w-6 h-6 text-green-600"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                                        ></path>
                                    </svg>
                                    <h4 className="text-l font-bold">
                                        Difficulty
                                    </h4>
                                    <p>
                                        {data?.recipe?.difficulty?.toLocaleLowerCase()}
                                    </p>
                                </span>
                                <span>
                                    <svg
                                        className="w-6 h-6 text-green-600"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                                        ></path>
                                    </svg>
                                    <h4 className="text-l font-bold">Serves</h4>
                                    <p>{data?.recipe.servings}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-3">
                            <h3 className="text-2xl font-bold w-full border-b-2 mb-2">
                                Ingredients
                            </h3>
                            {data?.recipe?.ingredients?.map(
                                ({
                                    id,
                                    name,
                                    quantity,
                                    quantityType,
                                    description,
                                }) => {
                                    return (
                                        <div
                                            key={id}
                                            className="w-full border-b-2 py-2"
                                        >
                                            {name} - {quantity}{" "}
                                            {quantityType?.toLocaleLowerCase()}
                                            <span className="block italic">
                                                {description}
                                            </span>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                        <div className="md:col-span-9 col-span-12">
                            <h3 className="text-2xl font-bold w-full border-b-2 mb-2">
                                Instructions
                            </h3>
                            <ReactMarkdown
                                className="prose prose-slate dark:prose-invert"
                                rehypePlugins={[rehypeSanitize]}
                                remarkPlugins={[
                                    [remarkGfm, { singleTilde: false }],
                                ]}
                                children={data?.recipe?.instructions || ""}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export const CreateRecipe: React.FC = () => {
    const navigate = useNavigate();
    return (
        <RecipeForm
            onCancel={() => navigate(-1)}
            onCompleted={({ id }) => navigate(`/recipes/${id}`)}
        />
    );
};

export const RecipeSearch: React.FC = () => {
    const { recipeId } = useParams();
    const navigate = useNavigate();

    if (!recipeId) {
        return (
            <div>
                <div className="relative">
                    <div className="fixed bottom-4 right-4 z-10">
                        <Add
                            onClick={() => navigate("/recipes/new")}
                            className="w-10 h-10"
                        />
                    </div>
                    <h4 className="text-2xl font-bold text-white">Recipes</h4>
                    <Search
                        header="Search recipes"
                        query={SEARCH_RECIPES_QUERY}
                        queryName={"recipes"}
                        onClick={({ id }) => {
                            navigate(`/recipes/${id}`);
                        }}
                    />
                </div>
            </div>
        );
    }

    return <UpdateRecipe />;
};

export const Recipe: React.FC = () => {
    return <Outlet />;
};

export default Recipe;
