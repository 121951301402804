import { UseFormRegister } from "react-hook-form";

export interface IInputProps {
    label: string;
    fieldName: string;
    type?: string;
    onClick?: () => void;
    readOnly?: boolean;
    register: UseFormRegister<any>;
    errors: any;
}

const Input: React.FC<IInputProps> = ({
    label,
    fieldName,
    type = "text",
    onClick,
    readOnly = false,
    register,
    errors,
}) => {
    return (
        <>
            <span className="flex items-center">
                <input
                    {...register(fieldName)}
                    type={type}
                    className={`${errors[fieldName] ? "ip-invalid" : ""} ${
                        type === "checkbox" ? "ip-checkbox" : "ip-base"
                    }`}
                    placeholder={label}
                    readOnly={readOnly}
                    onClick={onClick}
                />
                <label
                    htmlFor={fieldName}
                    className={`${
                        type === "checkbox" ? "ml-2 block text-sm" : "sr-only"
                    }`}
                >
                    {label}
                </label>
            </span>
            <p
                className={`${
                    errors[fieldName] ? "" : "hidden"
                } ip-error-message`}
            >
                {errors[fieldName]?.message}
            </p>
        </>
    );
};

export default Input;
