import gql from "graphql-tag";

export const GET_USER_GROUPS = gql`
    query GET_USER_GROUPS($id: ID!) {
        groups(
            where: {
                OR: [
                    { owner: { id: { equals: $id } } }
                    { members: { some: { id: { equals: $id } } } }
                ]
            }
        ) {
            id
            name
            owner {
                id
                name
            }
            members {
                id
                name
            }
        }
    }
`;

export const CREATE_GROUP = gql`
    mutation CREATE_GROUP($group: GroupCreateInput!) {
        createGroup(data: $group) {
            id
            name
            owner {
                id
                name
            }
            members {
                id
                name
            }
        }
    }
`;

export const UPDATE_GROUP = gql`
    mutation UPDATE_GROUP($id: ID!, $group: GroupUpdateInput!) {
        updateGroup(where: { id: $id }, data: $group) {
            id
            name
            owner {
                id
                name
            }
            members {
                id
                name
            }
        }
    }
`;

export const GET_GROUP_INVITES = gql`
    query GET_GROUP_INVITES($invitedId: ID!, $status: String!) {
        groupInvites(
            where: {
                AND: [
                    { invited: { id: { equals: $invitedId } } }
                    { status: { equals: $status } }
                ]
            }
        ) {
            id
            invited {
                id
                name
            }
            invitedBy {
                id
                name
            }
            group {
                id
                name
            }
            status
        }
    }
`;

export const CREATE_GROUP_INVITE = gql`
    mutation CREATE_GROUP_INVITE(
        $groupId: ID!
        $invitedById: ID!
        $invitedEmail: String!
    ) {
        createGroupInvite(
            data: {
                group: { connect: { id: $groupId } }
                invitedBy: { connect: { id: $invitedById } }
                invited: { connect: { email: $invitedEmail } }
                status: "PENDING"
            }
        ) {
            id
        }
    }
`;

export const UPDATE_GROUP_INVITE = gql`
    mutation UPDATE_GROUP_INVITE($id: ID!, $data: GroupInviteUpdateInput!) {
        updateGroupInvite(where: { id: $id }, data: $data) {
            id
            invited {
                id
                name
            }
            invitedBy {
                id
                name
            }
            group {
                id
                name
            }
            status
        }
    }
`;

export const ACCEPT_GROUP_INVITE = gql`
    mutation ACCEPT_GROUP_INVITE($id: ID!) {
        acceptGroupInvite(id: $id) {
            id
            status
        }
    }
`;
