import { useMutation } from "@apollo/client";
import { useState } from "react";
import useForm from "../../utils/useForm";
import { Group } from "../meals/MealPlanList";
import useUser from "../user/useUser";
import { CREATE_GROUP_INVITE } from "./group.api";

interface IGroupInviteFormProps {
    group?: Group;
    onSubmit?: (group: Group) => void;
    onCompleted?: (group: Group) => void;
    onError?: (error: any) => void;
    onCancel?: () => void;
    onInputChange?: (value: any) => void;
}

const GroupInviteForm: React.FC<IGroupInviteFormProps> = ({
    group = { id: "", owner: {} },
    onSubmit,
    onCompleted,
    onError,
    onCancel,
    onInputChange,
}) => {
    const { user } = useUser();
    const { inputs, handleInputChange } = useForm({});
    const [createGroupInvite, { error, loading }] = useMutation(
        CREATE_GROUP_INVITE,
        {
            onCompleted: ({ createGroupInvite }) =>
                onCompleted?.(createGroupInvite),
            onError: (error) => {
                onError?.(error);
            },
        }
    );

    return (
        <>
            <h2 className="text-3xl font-bold w-full mb-2">Invite</h2>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    try {
                        onSubmit?.(inputs);
                        createGroupInvite({
                            variables: {
                                groupId: group.id,
                                invitedById: user.id,
                                invitedEmail: inputs.email,
                            },
                        });
                    } catch (ex) {
                        console.error(`Failed to send invite: ${ex}`);
                    }
                }}
            >
                <fieldset className="">
                    <input
                        id="email"
                        name="email"
                        type="email"
                        value={inputs?.email}
                        className="ip-base"
                        placeholder="Email"
                        onChange={handleInputChange}
                    />
                    <div className="flex justify-end m-4">
                        <button
                            type="button"
                            className="btn-secondary"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button className="btn-primary ml-4" type="submit">
                            Send invite
                        </button>
                    </div>
                </fieldset>
            </form>
        </>
    );
};

export default GroupInviteForm;
