import { isSameDay, formatISO } from "date-fns";
import { useContext, useState } from "react";
import * as yup from "yup";
import { getDay } from "../../utils/DateUtil";
import Add from "../../icons/Add";
import Badge, { BadgeColors } from "../common/Badge";
import CardWithImage from "../common/CardWithImage";
import Card from "../common/Card";
import { gql, useMutation } from "@apollo/client";
import SearchModal from "../common/SearchModal";
import { SEARCH_RECIPES_QUERY } from "../recipe/recipe.query";
import { CREATE_MEAL, DELETE_MEAL, UPDATE_MEAL } from "./meal.mutation";
import useUser from "../user/useUser";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "../common/ButtonGroup";
import { MealFilterContext } from "../../routes/MealPlans";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../common/Input";

export interface Food {
    id: string;
    name: string;
    description: string;
    type: string;
}
export interface Ingredient {
    id: string;
    name: string;
    description: string;
    recipe: Recipe;
    food: Food;
    quantity: number;
    quantityType: string;
    ingredients: Ingredient;
}

export interface CloudinaryImage {
    id: string;
    altText: string;
    image: {
        publicUrlTransformed: string;
    };
}
export interface Recipe {
    id: string;
    name: string;
    description: string;
    instructions: string;
    difficulty: string;
    cookingTime: number;
    prepTime: number;
    servings: number;
    photo: CloudinaryImage;
    ingredients: Ingredient[];
    author: User;
    contributors: User[];
    source: {
        id: string;
        name: string;
        author: string;
        link: string;
        type: string;
    };
    isPrivate: boolean;
}

export interface Group {
    id: string;
    name: string;
    owner: User;
    members: User[];
    isPrivate: boolean;
    mealPlans: MealPlan[];
}

export interface User {
    id: string;
    name: string;
    email: string;
    groups: Group[];
    recipesAuthored: Recipe[];
    recipesContributed: Recipe[];
    mealPlans: MealPlan[];
}

export interface Meal {
    id: string;
    name: string;
    type: string;
    description: string;
    date: Date;
    recipe: Recipe;
    owner: User;
}
export interface MealInput {
    id?: string;
    name?: string;
    type?: string;
    description?: string;
    date: Date;
    recipe?: Recipe;
    owner: User;
}

export interface MealPlan {
    id: string;
    start: Date;
    end: Date;
    meals: Meal;
    owner: User;
    group: Group;
}

export interface IMealPlanListProps {
    meals: Meal[];
    dates: Date[];
    loading: boolean;
}

// const useDebounce = (debounceFn: any, timeout: number = 1000) => {
//     const [debounced, setDebounced] = useState<null | any>(null);

//     useEffect(() => {
//         if(debounced) {
//             debounced.cancel();
//         }

//         if(debounceFn) {
//             setDebounced(_.debounce(debounceFn, timeout));
//         }
//     }, [debounceFn, debounced, timeout]);

//     return [debounced, setDebounced];
// }

const mealTypeOptions = [
    {
        label: "Breakfast",
        value: "BREAKFAST",
    },
    {
        label: "Brunch",
        value: "BRUNCH",
    },
    {
        label: "Lunch",
        value: "LUNCH",
    },
    {
        label: "Dinner",
        value: "DINNER",
    },
    {
        label: "Supper",
        value: "SUPPER",
    },
    {
        label: "Snack",
        value: "SNACK",
    },
    {
        label: "Other",
        value: "OTHER",
    },
];

interface IMealFormProps {
    className?: string;
    onSubmit?: (meal: Meal) => void;
    onCompleted?: (meal: Meal) => void;
    onError?: (error: any) => void;
    onCancel?: () => void;
    onInputChange?: (value: any) => void;
    meal: MealInput;
}
const mealFormSchema = yup
    .object({
        id: yup.string(),
        recipe: yup.object().nullable().required(),
        name: yup.string().required("Recipe is required"),
        type: yup.string().required(),
        date: yup.date().required(),
        hasNoRecipeRef: yup.boolean(),
    })
    .required();
const MealForm: React.FC<IMealFormProps> = ({
    className,
    onSubmit,
    onCompleted,
    onError,
    onCancel,
    onInputChange,
    meal,
}) => {
    const mealFilterOption = useContext(MealFilterContext);
    const [toggleSearchRecipeModal, setToggleSearchRecipeModal] =
        useState(false);

    const {
        register,
        handleSubmit,
        getValues,
        setValue: setInputValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            recipe: null,
            name: meal?.recipe?.name,
            hasNoRecipeRef: Boolean(meal?.recipe?.name) && !meal?.recipe,
            type: mealTypeOptions[0].value,
            ...meal,
        },
        resolver: yupResolver(mealFormSchema),
    });
    const watchedHasNoRecipeRefValue = watch("hasNoRecipeRef");
    const watchedTypeValue = watch("type");
    const [createMeal, { error, loading }] = useMutation(CREATE_MEAL, {
        update(cache, { data: { createMeal } }) {
            cache.modify({
                fields: {
                    meals(existingMeals = []) {
                        const newMealRef = cache.writeFragment({
                            data: createMeal,
                            fragment: gql`
                                fragment NewMeal on Meal {
                                    id
                                    name
                                    type
                                    date
                                }
                            `,
                        });
                        return [...existingMeals, newMealRef];
                    },
                },
            });
        },
        onCompleted: ({ createMeal }) => onCompleted?.(createMeal),
        onError: (error) => {
            onError?.(error);
        },
    });
    const [updateMeal] = useMutation(UPDATE_MEAL, {
        update(cache, { data: { updateMeal } }) {
            cache.modify({
                fields: {
                    meals(existingMeals = []) {
                        cache.updateFragment(
                            {
                                id: updateMeal.id,
                                fragment: gql`
                                    fragment UpdateMeal on Meal {
                                        id
                                        name
                                        type
                                        date
                                        recipe {
                                            id
                                            name
                                        }
                                        owner {
                                            id
                                        }
                                    }
                                `,
                            },
                            () => updateMeal
                        );

                        return [...existingMeals];
                    },
                },
            });
        },
        onCompleted: ({ createMeal }) => onCompleted?.(createMeal),
        onError: (error) => {
            onError?.(error);
        },
    });
    const handleMealFormSubmit = (mealInput: Meal | any) => {
        let baseData: any = {
            date: formatISO(new Date(mealInput?.date), {
                representation: "date",
            }),
            type: mealInput?.type,
            recipe: mealInput?.recipe && {
                connect: {
                    id: mealInput?.recipe?.id,
                },
            },
            name: mealInput?.name,
        };

        if (mealFilterOption) {
            baseData = {
                ...baseData,
                group: {
                    connect: {
                        id: mealFilterOption,
                    },
                },
            };
        } else {
            baseData = {
                ...baseData,
                owner: {
                    connect: {
                        id: mealInput?.owner?.id,
                    },
                },
            };
        }
        if (mealInput.id) {
            const disconnectRecipe = mealInput.hasNoRecipeRef
                ? {
                      recipe: {
                          disconnect: true,
                      },
                  }
                : {};
            updateMeal({
                variables: {
                    id: mealInput?.id,
                    data: {
                        ...baseData,
                        ...disconnectRecipe,
                    },
                },
            });
        } else {
            createMeal({
                variables: {
                    data: {
                        ...baseData,
                    },
                },
            });
        }
        onSubmit?.(mealInput);
    };
    return (
        <div className={`${className} w-full`}>
            <span className={toggleSearchRecipeModal ? "" : "invisible"}>
                <SearchModal
                    header="Search recipes"
                    query={SEARCH_RECIPES_QUERY}
                    queryName={"recipes"}
                    onClose={() =>
                        setToggleSearchRecipeModal(!toggleSearchRecipeModal)
                    }
                    onClick={(recipe) => {
                        setInputValue("recipe", recipe);
                        setInputValue("name", recipe?.name, {
                            shouldValidate: true,
                        });
                        setToggleSearchRecipeModal(!toggleSearchRecipeModal);
                        onInputChange?.(recipe);
                    }}
                />
            </span>
            <form
                onSubmit={handleSubmit(async (mealInputs) => {
                    try {
                        await handleMealFormSubmit(mealInputs);
                    } catch (ex) {}
                })}
            >
                <div>
                    <Input
                        fieldName="name"
                        label="Recipe"
                        readOnly={!watchedHasNoRecipeRefValue}
                        register={register}
                        errors={errors}
                        onClick={() => {
                            if (watchedHasNoRecipeRefValue) {
                                return;
                            }
                            setToggleSearchRecipeModal(
                                !toggleSearchRecipeModal
                            );
                        }}
                    />
                </div>
                <div className="mb-4">
                    <Input
                        fieldName="hasNoRecipeRef"
                        label="Manually enter recipe name"
                        type="checkbox"
                        register={register}
                        errors={errors}
                    />
                </div>
                <div>
                    <ButtonGroup
                        buttons={mealTypeOptions.map(({ label, value }) => ({
                            label,
                            isSelected: watchedTypeValue === value,
                            onClick: () => setInputValue("type", value),
                        }))}
                    />
                </div>
                <div className="flex justify-end mt-4">
                    <button
                        onClick={onCancel}
                        className="btn-secondary w-1/3 md:w-1/4"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn-primary w-1/3 md:w-1/4 ml-4"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

interface IMealCardProps {
    meal: Meal;
    onSubmit: (meal: Meal) => void;
}
const MealCard: React.FC<IMealCardProps> = ({ meal, onSubmit }) => {
    const navigate = useNavigate();
    const [toggleUpdateMealForm, setToggleUpdateMealForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteMeal] = useMutation(DELETE_MEAL, {
        update(cache, { data: { deleteMeal } }) {
            const normalizedId = cache.identify({
                id: deleteMeal.id,
                __typename: "Meal",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
        },
    });
    return toggleUpdateMealForm ? (
        <Card>
            <MealForm
                className="p-2"
                meal={meal}
                onSubmit={() => {
                    setToggleUpdateMealForm(false);
                    setIsLoading(true);
                }}
                onCompleted={() => {
                    setIsLoading(false);
                }}
                onError={() => {
                    setIsLoading(false);
                }}
                onCancel={() => setToggleUpdateMealForm(false)}
            />
        </Card>
    ) : (
        <CardWithImage
            isLoading={isLoading}
            title={meal.name}
            image={meal.recipe?.photo?.image?.publicUrlTransformed}
            options={[
                {
                    label: "Edit",
                    onClick: () => setToggleUpdateMealForm(true),
                },
                {
                    label: "Delete",
                    onClick: () => {
                        deleteMeal({ variables: { id: meal.id } });
                    },
                    hightlight: true,
                },
            ]}
            onClick={() => navigate(`/recipes/${meal.recipe.id}`)}
        >
            <Badge label={meal.type} color={BadgeColors.YELLOW} />
        </CardWithImage>
    );
};

interface IMealPlanDay {
    date: Date;
    meals: Meal[];
}
const MealPlanDay: React.FC<IMealPlanDay> = ({ date, meals }) => {
    const { user } = useUser();
    const [toggleNewMealForm, setToggleNewMealForm] = useState(false);
    const [isNewMealLoading, setIsNewMealLoading] = useState(false);

    return (
        <div className="flex flex-col">
            <h4 className="text-2xl font-bold mb-2">{getDay(date)}</h4>
            {meals.map((meal) => (
                <div key={meal.id} className="pb-2">
                    <MealCard meal={meal} onSubmit={() => {}} />
                </div>
            ))}
            {isNewMealLoading ? (
                <CardWithImage isLoading={isNewMealLoading} title="" image="" />
            ) : (
                <Card>
                    <div className="flex flex-col justify-center items-center w-full p-2">
                        {!toggleNewMealForm ? (
                            <Add
                                onClick={() =>
                                    setToggleNewMealForm(!toggleNewMealForm)
                                }
                            />
                        ) : (
                            <MealForm
                                meal={{
                                    date,
                                    owner: {
                                        ...user,
                                    },
                                }}
                                onSubmit={() => {
                                    setToggleNewMealForm(false);
                                    setIsNewMealLoading(true);
                                }}
                                onCompleted={() => {
                                    setToggleNewMealForm(false);
                                    setIsNewMealLoading(false);
                                }}
                                onError={() => {
                                    setToggleNewMealForm(false);
                                    setIsNewMealLoading(false);
                                }}
                                onCancel={() => setToggleNewMealForm(false)}
                            />
                        )}
                    </div>
                </Card>
            )}
        </div>
    );
};

export function MealPlanList({ meals, dates, loading }: IMealPlanListProps) {
    const days = dates.map((date) => {
        const mealsOnDate = meals.filter(({ date: mealDate }) =>
            isSameDay(new Date(date), new Date(mealDate))
        );
        return (
            <div key={date.toString()} className="mb-4">
                <MealPlanDay date={date} meals={mealsOnDate} />
            </div>
        );
    });
    return (
        <div className="">
            <div>{days}</div>
        </div>
    );
}
