import { gql } from "@apollo/client";

export const CREATE_MEAL = gql`
    mutation CREATE_MEAL($data: MealCreateInput!) {
        createMeal(data: $data) {
            id
            name
            type
            date
            recipe {
                id
                name
            }
            owner {
                id
            }
        }
    }
`;

export const UPDATE_MEAL = gql`
    mutation UPDATE_MEAL($id: ID, $data: MealUpdateInput!) {
        updateMeal(where: { id: $id }, data: $data) {
            id
            name
            type
            date
            recipe {
                id
                name
            }
            owner {
                id
            }
        }
    }
`;

export const DELETE_MEAL = gql`
    mutation DELETE_MEAL($id: ID) {
        deleteMeal(where: { id: $id }) {
            id
        }
    }
`;
