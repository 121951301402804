import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router";
import Card from "../components/common/Card";
import Loader from "../components/common/Loader";
import Modal from "../components/common/Modal";
import {
    ACCEPT_GROUP_INVITE,
    GET_GROUP_INVITES,
    GET_USER_GROUPS,
    UPDATE_GROUP_INVITE,
} from "../components/group/group.api";
import GroupForm from "../components/group/GroupForm";
import { Group } from "../components/meals/MealPlanList";
import useUser from "../components/user/useUser";
import Add from "../icons/Add";

export default function Groups() {
    const { user } = useUser();
    const navigate = useNavigate();
    const { loading, error, data } = useQuery<{ groups: Group[] }>(
        GET_USER_GROUPS,
        {
            variables: {
                id: user.id,
            },
        }
    );
    const {
        loading: invitesLoading,
        error: invitesError,
        data: pendingInvitesData,
    } = useQuery<{ groupInvites: any[] }>(GET_GROUP_INVITES, {
        variables: {
            invitedId: user.id,
            status: "PENDING",
        },
    });
    const [acceptGroupInvite] = useMutation(ACCEPT_GROUP_INVITE, {
        refetchQueries: [GET_USER_GROUPS, GET_GROUP_INVITES],
    });
    const [updateGroupInvite] = useMutation(UPDATE_GROUP_INVITE, {
        refetchQueries: [GET_USER_GROUPS, GET_GROUP_INVITES],
    });
    const handleAcceptInvite = async (id: string) => {
        await acceptGroupInvite({
            variables: {
                id,
            },
        });
    };
    const handleRejectInvite = async (id: string) => {
        await updateGroupInvite({
            variables: {
                id,
                data: {
                    status: "REJECTED",
                },
            },
        });
    };
    const [openNewGroupModal, setOpenNewGroupModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<Group | undefined>(
        undefined
    );

    if (loading) return <Loader>Loading...</Loader>;
    if (error) return <div>{`Error! ${error.message}`}</div>;

    return (
        <div>
            <div className="flex justify-between mb-4">
                <h2 className="text-3xl font-bold w-full mb-2">Groups</h2>
                <button
                    className="btn-primary"
                    onClick={() => setOpenNewGroupModal(true)}
                >
                    Create group
                </button>
            </div>
            <div className="flex flex-wrap">
                {data?.groups.map((group) => (
                    <Card
                        key={group.id}
                        className="flex-grow mr-4 w-full mb-4 md:max-w-[30%]"
                        onClick={() => {
                            setSelectedGroup(group);
                            setOpenNewGroupModal(true);
                        }}
                    >
                        <div className="flex flex-col p-4 leading-normal overflow-hidden">
                            <h5 className="mb-2 text-md md:text-xl font-bold tracking-tight text-gray-900 dark:text-white break-words text-ellipsis overflow-hidden line-clamp-2">
                                {group.name}
                            </h5>
                            <p className="mb-2 text-sm md:text-l font-bold tracking-tight text-gray-900 dark:text-white break-words text-ellipsis overflow-hidden line-clamp-2">
                                Owner: {group.owner.name}
                            </p>
                            <p className="mb-2 text-sm md:text-l font-bold tracking-tight text-gray-900 dark:text-white break-words text-ellipsis overflow-hidden line-clamp-2">
                                Members: {group.members.length}
                            </p>
                        </div>
                    </Card>
                ))}
            </div>
            {openNewGroupModal ? (
                <Modal>
                    <GroupForm
                        group={selectedGroup}
                        onCompleted={() => {
                            setSelectedGroup(undefined);
                            setOpenNewGroupModal(false);
                        }}
                        onCancel={() => {
                            setSelectedGroup(undefined);
                            setOpenNewGroupModal(false);
                        }}
                    />
                </Modal>
            ) : null}
            <div className="flex justify-between mb-4">
                <h2 className="text-3xl font-bold w-full mb-2">
                    Pending invites
                </h2>
            </div>
            <div className="flex flex-wrap">
                {pendingInvitesData?.groupInvites.map((invite) => (
                    <Card
                        key={invite.id}
                        className="flex-grow mr-4 w-full mb-4 md:max-w-[30%]"
                        onClick={() => {}}
                    >
                        <div className="w-full">
                            <div className="flex flex-col p-4 leading-normal overflow-hidden">
                                <h5 className="mb-2 text-md md:text-xl font-bold tracking-tight text-gray-900 dark:text-white break-words text-ellipsis overflow-hidden line-clamp-2">
                                    {invite.group.name}
                                </h5>
                                <p className="mb-2 text-sm md:text-l font-bold tracking-tight text-gray-900 dark:text-white break-words text-ellipsis overflow-hidden line-clamp-2">
                                    Invited by: {invite.invitedBy.name}
                                </p>
                            </div>
                            <div className="w-full inline-flex justify-center p-4">
                                <button
                                    className="btn-primary mr-4"
                                    onClick={() =>
                                        handleAcceptInvite(invite.id)
                                    }
                                >
                                    Accept
                                </button>
                                <button
                                    className="btn-warning"
                                    onClick={() =>
                                        handleRejectInvite(invite.id)
                                    }
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
}
