import { useMutation } from "@apollo/client";
import MDEditor from "@uiw/react-md-editor";
import { useCallback, useEffect, useMemo, useState } from "react";
import rehypeSanitize from "rehype-sanitize";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { createWorker } from "tesseract.js";
import Add from "../../icons/Add";
import useForm from "../../utils/useForm";
import Modal, { Overlay } from "../common/Modal";
import { Recipe } from "../meals/MealPlanList";
import useUser from "../user/useUser";
import IngredientForm from "./IngredientsForm";
import {
    CREATE_RECIPE,
    getRecipeCreateInput,
    getRecipeUpdateInput,
    UPDATE_RECIPE,
} from "./recipe.query";
import Loader from "../common/Loader";
import Edit from "../../icons/Edit";
import Trash from "../../icons/Trash";
import ButtonGroup from "../common/ButtonGroup";

interface IRecipeFormProps {
    recipe?: Recipe;
    onSubmit?: (recipe: Recipe) => void;
    onCompleted?: (recipe: Recipe) => void;
    onError?: (error: any) => void;
    onCancel?: () => void;
    onInputChange?: (value: any) => void;
}

const worker = createWorker({
    logger: (m) => {
        console.log("M " + m.progress);
    },
});

const difficultyOptions = [
    {
        label: "Easy",
        value: "EASY",
    },
    {
        label: "Medium",
        value: "MEDIUM",
    },
    {
        label: "Hard",
        value: "HARD",
    },
];
const RecipeForm: React.FC<IRecipeFormProps> = ({
    recipe = {},
    onSubmit,
    onCompleted,
    onError,
    onCancel,
    onInputChange,
}) => {
    const { user } = useUser();
    const { inputs, handleInputChange, setInputValue } = useForm({
        author: user,
        ...recipe,
    });
    const [openIngredientsFormModal, setOpenIngredientsFormModal] =
        useState(false);
    const [selectedIngredientIndex, setSelectedIngredientIndex] = useState(-1);
    const [ingredientsToDelete, setIngredientsToDelete] = useState<any>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    const convertImageToRecipe = useCallback(
        async (imageUri: any) => {
            setShowLoader(true);
            if (!imageUri) return;
            try {
                const tessWorker = await worker;
                await tessWorker.loadLanguage("eng");
                await tessWorker.initialize("eng");
                const {
                    data: { text },
                } = await tessWorker.recognize(imageUri);
                setInputValue("instructions", text);
            } catch (ex: any) {
                console.error(ex);
            }

            setShowLoader(false);
        },
        [setInputValue]
    );
    const [createRecipe, { error: createError, loading: createLoading }] =
        useMutation(CREATE_RECIPE, {
            onCompleted: ({ createRecipe: createdRecipe }) =>
                // @ts-ignore
                onCompleted(createdRecipe),
        });
    const [updateRecipe, { error: updateError, loading: updateLoading }] =
        useMutation(UPDATE_RECIPE, {
            onCompleted: ({ updateRecipe: updatedRecipe }) =>
                // @ts-ignore
                onCompleted(updatedRecipe),
        });
    const handleRecipeFormSubmit = async (recipeInput: Recipe) => {
        if (recipeInput.id) {
            await updateRecipe({
                variables: {
                    id: recipeInput.id,
                    data: getRecipeUpdateInput(
                        recipeInput,
                        ingredientsToDelete
                    ),
                },
            });
        } else {
            await createRecipe({
                variables: {
                    data: getRecipeCreateInput(recipeInput),
                },
            });
        }
    };
    function handleImageChange(e: any) {
        const file = e.target.files[0];
        if (!file) return;
        const reader = new FileReader();
        reader.onloadend = () => {
            const imageDataUri = reader.result;
            console.log({ imageDataUri });
            convertImageToRecipe(imageDataUri);
        };
        reader.readAsDataURL(file);
    }

    if (createLoading || updateLoading) {
        return (
            <Overlay>
                <Loader>Saving...</Loader>
            </Overlay>
        );
    }
    return (
        <div>
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    try {
                        await handleRecipeFormSubmit(inputs);
                    } catch (ex) {
                        console.error(`Failed to save recipe: ${ex}`);
                    }
                }}
                className={`grid columns-12`}
            >
                <fieldset className="md:col-start-1 md:col-end-9">
                    <label htmlFor="name" className="ip-label">
                        Name
                    </label>
                    <input
                        id="name"
                        name="name"
                        required
                        value={inputs.name}
                        className="ip-base"
                        placeholder="Banoffee Pie"
                        onChange={handleInputChange}
                    />
                    <label htmlFor="description" className="ip-label">
                        Description
                    </label>
                    <textarea
                        id="description"
                        name="description"
                        required
                        value={inputs.description}
                        className="ip-base"
                        placeholder="A simple and delicious dessert..."
                        onChange={handleInputChange}
                    />
                    <label htmlFor="image" className="ip-label">
                        Image
                    </label>
                    <input
                        id="image"
                        name="image"
                        type="file"
                        className="ip-base"
                        onChange={handleInputChange}
                    />
                    <label htmlFor="difficulty" className="ip-label">
                        Difficulty
                    </label>
                    <ButtonGroup
                        id="difficulty"
                        buttons={difficultyOptions.map(({ label, value }) => ({
                            label,
                            isSelected: inputs.difficulty === value,
                            onClick: () => setInputValue("difficulty", value),
                        }))}
                        className="mt-2 mb-2"
                    />
                    <label htmlFor="prepTime" className="ip-label">
                        Prep time (minutes)
                    </label>
                    <input
                        id="prepTime"
                        name="prepTime"
                        type="number"
                        required
                        value={inputs.prepTime}
                        className="ip-base"
                        placeholder="10"
                        onChange={handleInputChange}
                    />
                    <label htmlFor="cookingTime" className="ip-label">
                        Cooking time (minutes)
                    </label>
                    <input
                        id="cookingTime"
                        name="cookingTime"
                        type="number"
                        required
                        value={inputs.cookingTime}
                        className="ip-base"
                        placeholder="10"
                        onChange={handleInputChange}
                    />
                    <label htmlFor="servings" className="ip-label">
                        Servings
                    </label>
                    <input
                        id="servings"
                        name="servings"
                        type="number"
                        required
                        value={inputs.servings}
                        className="ip-base"
                        placeholder="10"
                        onChange={handleInputChange}
                    />
                    <span className="flex flex-col md:flex-row">
                        <label
                            className="block md:inline-block"
                            htmlFor="instructionsFileInput"
                        >
                            <span className="link-btn-primary mt-2 mb-4">
                                Upload instructions image
                            </span>
                            <input
                                id="instructionsFileInput"
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                className="hidden"
                                onChange={handleImageChange}
                            />
                        </label>
                        <span className="md:self-center md:mx-4 text-base font-semibold w-auto h-auto">
                            or
                        </span>
                        <label
                            className="block md:inline-block"
                            htmlFor="cameraFileInput"
                        >
                            <span className="link-btn-primary mt-4 md:mt-2 mb-4">
                                Scan instructions using camera
                            </span>
                            <input
                                id="cameraFileInput"
                                type="file"
                                accept="image/*"
                                capture="environment"
                                className="hidden"
                                onChange={handleImageChange}
                            />
                        </label>
                    </span>
                    <MDEditor
                        value={inputs.instructions}
                        onChange={(value) =>
                            setInputValue("instructions", value)
                        }
                        previewOptions={{
                            rehypePlugins: [[rehypeSanitize]],
                        }}
                        preview="edit"
                    />
                </fieldset>
                <div className="md:col-start-10 md:col-end-12">
                    <h4 className="text-xl font-bold w-full border-b-2 mb-2">
                        Ingredients
                    </h4>
                    {inputs.ingredients?.map(
                        (ingredient: any = {}, index: number) => {
                            return (
                                <div key={ingredient.name}>
                                    <div className="flex flex-row content-center justify-between bg-white mb-2 rounded-lg border shadow-md dark:bg-gray-800 dark:border-gray-700">
                                        <div className="p-4 w-auto max-w-[66%]">
                                            <p className="break-words">
                                                {ingredient.food.name} -{" "}
                                                {ingredient.quantity}{" "}
                                                {ingredient.quantityType}
                                            </p>
                                            <p>{ingredient.description}</p>
                                        </div>
                                        <span className="p-4 m-0 md:ml-auto md:mr-4">
                                            <Edit
                                                className="w-4 h-4 mr-4"
                                                onClick={() => {
                                                    setSelectedIngredientIndex(
                                                        index
                                                    );
                                                    setOpenIngredientsFormModal(
                                                        true
                                                    );
                                                }}
                                            />
                                            <Trash
                                                className="w-4 h-4"
                                                onClick={() => {
                                                    setIngredientsToDelete([
                                                        ...ingredientsToDelete,
                                                        {
                                                            id: inputs
                                                                .ingredients[
                                                                index
                                                            ].id,
                                                        },
                                                    ]);
                                                    inputs.ingredients.splice(
                                                        index,
                                                        1
                                                    );
                                                }}
                                            />
                                        </span>
                                    </div>
                                </div>
                            );
                        }
                    )}
                    <div>
                        <div className="flex justify-center content-center bg-white rounded-lg border shadow-md dark:bg-gray-800 dark:border-gray-700">
                            <Add
                                className="m-4"
                                onClick={() => {
                                    setSelectedIngredientIndex(-1);
                                    setOpenIngredientsFormModal(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="md:col-span-12 inline-flex justify-end mt-4">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="btn-secondary w-1/3 md:w-1/4 line-clamp-1"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn-primary w-1/3 md:w-1/4 ml-4 line-clamp-1"
                    >
                        Save
                    </button>
                </div>
            </form>
            {!openIngredientsFormModal ? null : (
                <Modal>
                    <IngredientForm
                        ingredient={
                            inputs.ingredients?.[selectedIngredientIndex]
                        }
                        onSubmit={(updatedIngredient) => {
                            if (selectedIngredientIndex >= 0) {
                                inputs.ingredients[selectedIngredientIndex] =
                                    updatedIngredient;
                            } else {
                                if (inputs.ingredients) {
                                    inputs.ingredients.push(updatedIngredient);
                                } else {
                                    inputs.ingredients = [updatedIngredient];
                                }
                            }

                            setOpenIngredientsFormModal(false);
                        }}
                        onCompleted={(updatedIngredient) => {
                            const ingredientIndex =
                                selectedIngredientIndex >= 0
                                    ? selectedIngredientIndex
                                    : inputs.ingredients.length - 1;
                            const ingredient = {
                                ...inputs.ingredients[ingredientIndex],
                                ...updatedIngredient,
                            };
                            inputs.ingredients[ingredientIndex] = ingredient;
                        }}
                        onCancel={() => {
                            setSelectedIngredientIndex(-1);
                            setOpenIngredientsFormModal(false);
                        }}
                    />
                </Modal>
            )}
            <Overlay className={`${showLoader ? "" : "hidden"}`}>
                <Loader>Loading...</Loader>
            </Overlay>
        </div>
    );
};

export default RecipeForm;
