import { PropsWithChildren } from "react";
import { IPropsWithChildrenAndClassName } from "../../types/IPropsWithChildrenAndClassName";
import OptionsMenu from "./OptionsMenu";

export interface ICardOptionsMenuProps {
    label: string;
    onClick: (...args: any) => void;
    hightlight?: boolean;
}
export interface ICardProps extends IPropsWithChildrenAndClassName {
    onClick?: (...args: any) => void;
    options?: ICardOptionsMenuProps[];
}

const Card: React.FC<ICardProps> = ({
    children,
    className,
    onClick,
    options = [],
}) => {
    return (
        <div id="card" className={`${className} relative md:max-w-2xl`}>
            <OptionsMenu key="options" options={options} className="mt-4" />
            <div
                className={`${
                    onClick
                        ? "hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                        : ""
                } relative flex flex-row items-center bg-white rounded-lg border shadow-md dark:border-gray-700 dark:bg-gray-800`}
                onClick={onClick}
            >
                {children}
            </div>
        </div>
    );
};

export default Card;
