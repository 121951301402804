import { PropsWithChildren } from "react";

export interface IModalProps extends PropsWithChildren {
    className?: string;
}

export const Overlay: React.FC<IModalProps> = ({ children, className }) => {
    return (
        <div
            className={`${className} fixed z-50 w-screen h-screen bg-gray-500 bg-opacity-50 top-0 left-0 flex justify-center items-center`}
        >
            {children}
        </div>
    );
};

export const Modal: React.FC<IModalProps> = ({ children, className }) => {
    return (
        <Overlay className={className}>
            <div className="fixed top-12 w-auto max-w-[calc(78rem)] max-h-[75%] overflow-x-hidden overflow-y-scroll flex flex-col p-2 bg-white rounded-lg border shadow-md dark:border-gray-700 dark:bg-gray-800">
                {children}
            </div>
        </Overlay>
    );
};

export default Modal;
