import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/link-error";

const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            );
        if (networkError)
            console.log(
                `[Network error]: ${networkError}. Backend is unreachable. Is it running?`
            );
    }),
    // this uses apollo-link-http under the hood, so all the options here come from that package
    createUploadLink({
        uri:
            process.env.NODE_ENV === "development"
                ? process.env.REACT_APP_DEV_ENDPOINT
                : process.env.REACT_APP_PROD_ENDPOINT,
        fetchOptions: {
            credentials: "include",
        },
    }),
]);

export default new ApolloClient({
    cache: new InMemoryCache(),
    link,
});
