import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import client from "./apollo-client";
import "./index.css";
import App from "./routes/App";
import reportWebVitals from "./reportWebVitals";
import SignIn from "./components/auth/SignIn";
import AuthenticatedRoute from "./components/auth/AuthenticatedRoute";
import MealPlans from "./routes/MealPlans";
import Recipe, {
    CreateRecipe,
    RecipeSearch,
    UpdateRecipe,
} from "./components/recipe/Recipe";
import SignUp from "./components/auth/SignUp";
import logout from "./components/auth/LogOut";
import Groups from "./routes/Groups";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: (
                    <AuthenticatedRoute>
                        <MealPlans />
                    </AuthenticatedRoute>
                ),
            },
            {
                path: "mealplans",
                element: (
                    <AuthenticatedRoute>
                        <MealPlans />
                    </AuthenticatedRoute>
                ),
            },
            {
                path: "recipes",
                children: [
                    {
                        index: true,
                        element: <RecipeSearch />,
                    },
                    {
                        path: "new",
                        element: <CreateRecipe />,
                    },
                    {
                        path: ":recipeId",
                        element: <UpdateRecipe />,
                    },
                ],
                element: (
                    // <AuthenticatedRoute>
                        <Recipe />
                    // </AuthenticatedRoute>
                ),
            },
            {
                path: "groups",
                element: (
                    <AuthenticatedRoute>
                        <Groups />
                    </AuthenticatedRoute>
                ),
            },
            {
                path: "/logout",
                action: () => logout(client),
            },
        ],
    },
    {
        path: "/signin",
        element: <SignIn />,
    },
    {
        path: "/register",
        element: <SignUp />,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <RouterProvider router={router} />
        </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
