import classNames from "../../utils/Classnames";

export interface IBadgeProps {
    color?: BadgeColors;
    label: string;
}

export enum BadgeColors {
    BLUE = "bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-900",
    GREEN = "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900",
    ORANGE = "bg-orange-100 text-orange-800 dark:bg-orange-200 dark:text-orange-900",
    RED = "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900",
    YELLOW = "bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900",
}

const Badge: React.FC<IBadgeProps> = ({ color = BadgeColors.GREEN, label }) => {
    const styles = classNames(
        color,
        "text-xs font-semibold mr-2 px-2.5 py-0.5 rounded w-fit"
    );

    return <span className={styles}>{label}</span>;
};

export default Badge;
