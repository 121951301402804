import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GET_CURRENT_USER } from "../user/userQueries";
import { SIGNIN_MUTATION } from "./auth.api";
import Input from "../common/Input";

export interface SignInInput {
    email: string;
    password: string;
}

const schema = yup
    .object({
        email: yup.string().email("Please enter a valid email").required(),
        password: yup.string().required(),
    })
    .required();

function Signin() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<SignInInput>({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });

    const handleCompleted = useCallback(() => {
        navigate("/");
    }, [navigate]);

    const [signin, { error, loading }] = useMutation(SIGNIN_MUTATION, {
        variables: getValues(),
        onCompleted: handleCompleted,
        refetchQueries: [{ query: GET_CURRENT_USER }],
        awaitRefetchQueries: true,
    });

    return (
        <div className=" px-2 sm:px-6 lg:px-8 flex justify-center content-center">
            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <Link to="/">
                            <svg
                                className="mx-auto h-12 w-auto text-green-600"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 10V3L4 14h7v7l9-11h-7z"
                                ></path>
                            </svg>
                        </Link>
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight">
                            Sign in to your account
                        </h2>
                    </div>
                    <form
                        className="mt-8 space-y-6"
                        method="POST"
                        onSubmit={handleSubmit(async () => {
                            try {
                                await signin();
                            } catch (ex) {}
                        })}
                    >
                        <input type="hidden" name="remember" value="true" />
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                                <Input
                                    fieldName="email"
                                    label="Email address"
                                    register={register}
                                    errors={errors}
                                />
                            </div>
                            <div>
                                <Input
                                    fieldName="password"
                                    label="Password"
                                    type="password"
                                    register={register}
                                    errors={errors}
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="ip-checkbox"
                                />
                                <label
                                    htmlFor="remember-me"
                                    className="ml-2 block text-sm"
                                >
                                    Remember me
                                </label>
                            </div>

                            <div className="text-sm">
                                <a href="#" className="link">
                                    Forgot your password?
                                </a>
                            </div>
                        </div>
                        <div className="text-sm">
                            No account?
                            <Link to="/register" className="link ml-2">
                                Sign up.
                            </Link>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="btn-primary group relative w-full"
                            >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <svg
                                        className="h-5 w-5 text-green-700 group-hover:text-green-600"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </span>
                                Sign in
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Signin;
