import { gql } from "@apollo/client";

export const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        authenticatedItem {
            ... on User {
                id
                email
                name
            }
        }
    }
`;
