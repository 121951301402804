import { ApolloClient } from "@apollo/client";
import { redirect } from "react-router";
import { GET_CURRENT_USER } from "../user/userQueries";
import { LOG_OUT_MUTATION } from "./auth.api";

export default async function logout(client: ApolloClient<object>) {
    await client.mutate({
        mutation: LOG_OUT_MUTATION,
        refetchQueries: [GET_CURRENT_USER],
    });

    return redirect("/");
}
