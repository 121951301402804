import { gql } from "@apollo/client";

export const GET_MEALS = gql`
    query GetMeals($where: MealWhereInput!) {
        meals(where: $where) {
            id
            name
            date
            type
            recipe {
                id
                name
                photo {
                    id
                    altText
                    image {
                        publicUrlTransformed
                    }
                }
            }
            owner {
                id
            }
        }
    }
`;
