import { IIconProps } from "../types/IIconProps";

const Add: React.FC<IIconProps> = ({
    disabled = false,
    onClick,
    className = "",
}) => {
    return (
        <button
            type="button"
            className={`w-8 h-8 ${className}`}
            onClick={onClick}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 506.4 506.4"
            >
                <circle
                    className={`${
                        disabled
                            ? "fill-gray-600"
                            : "fill-green-800 hover:fill-green-700"
                    }`}
                    cx="253.2"
                    cy="253.2"
                    r="249.2"
                />
                <path
                    className={`${disabled ? "fill-gray-400" : "fill-white"}`}
                    d="M360.4,233.2h-91.2v-90.8c0-6.4-5.2-9.2-11.6-9.2h-16c-6.4,0-12,2.8-12,9.2v90.8h-90.8  c-6.4,0-13.2,5.6-13.2,12v16c0,6.4,6.8,12,13.2,12h90.8v91.2c0,6.4,5.6,12.8,12,12.8h16c6.4,0,11.6-6.4,11.6-12.8v-91.2h91.2  c6.4,0,8.8-5.6,8.8-12v-18.4C369.2,236.4,366.8,233.2,360.4,233.2z"
                />
                <path d="M253.2,506.4C113.6,506.4,0,392.8,0,253.2S113.6,0,253.2,0s253.2,113.6,253.2,253.2S392.8,506.4,253.2,506.4z M253.2,8  C118,8,8,118,8,253.2s110,245.2,245.2,245.2s245.2-110,245.2-245.2S388.4,8,253.2,8z" />
                <path d="M257.6,381.2h-16c-8.4,0-16-8-16-16.8v-87.2h-86.8c-8,0-17.2-6.8-17.2-16v-16c0-9.2,9.2-16,17.2-16h86.8v-86.8  c0-8,6.4-13.2,16-13.2h16c9.6,0,15.6,5.2,15.6,13.2v86.8h85.2c8.4,0,14.8,6.8,14.8,16v16c0,10-4.8,16-12.8,16h-87.2v87.2  C273.2,373.2,266,381.2,257.6,381.2z M138.4,237.2c-4.4,0-9.2,4-9.2,8v16c0,4,4.8,8,9.2,8h90.8c2.4,0,4,1.6,4,4v91.2  c0,4.4,4,8.8,8,8.8h16c4,0,7.6-4.4,7.6-8.8v-91.2c0-2.4,1.6-4,4-4H360c3.2,0,4.8-2.8,4.8-8v-16c0-4-2.4-8-6.8-8h-88.8  c-2.4,0-4-1.6-4-4v-90.8c0-4.8-4.8-5.2-7.6-5.2h-16c-2.4,0-8,0.4-8,5.2v90.8c0,2.4-1.6,4-4,4H138.4z" />
            </svg>
        </button>
    );
};

export default Add;
