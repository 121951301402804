import { useMutation } from "@apollo/client";
import { useState } from "react";
import Add from "../../icons/Add";
import Trash from "../../icons/Trash";
import useForm from "../../utils/useForm";
import Modal, { Overlay } from "../common/Modal";
import Toast, { ToastTypes } from "../common/Toast";
import { Group } from "../meals/MealPlanList";
import useUser from "../user/useUser";
import { CREATE_GROUP, GET_USER_GROUPS, UPDATE_GROUP } from "./group.api";
import GroupInviteForm from "./GroupInviteForm";

interface IGroupFormProps {
    group?: Group;
    onSubmit?: (group: Group) => void;
    onCompleted?: (group: Group) => void;
    onError?: (error: any) => void;
    onCancel?: () => void;
    onInputChange?: (value: any) => void;
}

const GroupForm: React.FC<IGroupFormProps> = ({
    group,
    onSubmit,
    onCompleted,
    onError,
    onCancel,
    onInputChange,
}) => {
    const { user } = useUser();
    const [membersToRemove, setMembersToRemove] = useState<string[]>([]);
    const [openGroupInviteModal, setOpenGroupInviteModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const { inputs, handleInputChange, setInputValue } = useForm({
        ...group,
    });
    const [createGroup, { error, loading }] = useMutation(CREATE_GROUP, {
        optimisticResponse: {
            createGroup: {
                ...inputs,
                id: "temp",
                __typename: "Group",
            },
        },
        onCompleted: ({ createGroup }) => onCompleted?.(createGroup),
        onError: (error) => {
            onError?.(error);
        },
        refetchQueries: [GET_USER_GROUPS],
    });
    const [updateGroup] = useMutation(UPDATE_GROUP, {
        optimisticResponse: {
            updateGroup: {
                ...inputs,
            },
        },
        onCompleted: ({ updateGroup }) => onCompleted?.(updateGroup),
        onError: (error) => {
            onError?.(error);
        },
        refetchQueries: [GET_USER_GROUPS],
    });
    return (
        <>
            <h2 className="text-3xl font-bold w-full mb-2">Group</h2>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    try {
                        onSubmit?.(inputs);
                        const groupInput = {
                            name: inputs.name,
                            owner: {
                                connect: {
                                    id: user.id,
                                },
                            },
                            members: {
                                connect: [
                                    {
                                        id: user.id,
                                    },
                                ],
                            },
                        };
                        if (inputs.id) {
                            updateGroup({
                                variables: {
                                    id: inputs.id,
                                    group: {
                                        ...groupInput,
                                        members: {
                                            disconnect: membersToRemove.map(
                                                (id) => ({ id })
                                            ),
                                        },
                                    },
                                },
                            });
                        } else {
                            createGroup({
                                variables: {
                                    group: groupInput,
                                },
                            });
                        }
                    } catch (ex) {
                        console.error(`Failed to save Group: ${ex}`);
                    }
                }}
            >
                <fieldset className="">
                    <input
                        id="name"
                        name="name"
                        type="text"
                        value={inputs?.name}
                        className="ip-base"
                        placeholder="Name"
                        onChange={handleInputChange}
                    />
                    <div
                        className={`${
                            group?.id ? "" : "hidden"
                        } md:col-start-10 md:col-end-12`}
                    >
                        <h4 className="text-xl font-bold w-full border-b-2 mb-2">
                            Members
                        </h4>
                        {inputs.members?.map(
                            (member: any = {}, index: number) => {
                                return (
                                    <div key={member.name}>
                                        <div className="flex flex-row content-center justify-between bg-white mb-2 rounded-lg border shadow-md dark:bg-gray-800 dark:border-gray-700">
                                            <div className="p-4 w-auto max-w-[66%]">
                                                <p className="break-words">
                                                    {member.name}
                                                </p>
                                            </div>
                                            <span
                                                className={`${
                                                    group?.owner?.id === user.id
                                                        ? ""
                                                        : "hidden"
                                                } p-4 m-0 md:ml-auto md:mr-4`}
                                            >
                                                <Trash
                                                    className={`${
                                                        member.id ===
                                                        group?.owner?.id
                                                            ? "hidden"
                                                            : ""
                                                    } w-4 h-4`}
                                                    onClick={() => {
                                                        setMembersToRemove([
                                                            ...membersToRemove,
                                                            inputs.members?.[
                                                                index
                                                            ]?.id,
                                                        ]);
                                                        inputs.members.splice(
                                                            index,
                                                            1
                                                        );
                                                    }}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                );
                            }
                        )}
                        <div>
                            <div className="flex justify-center content-center bg-white rounded-lg border shadow-md dark:bg-gray-800 dark:border-gray-700">
                                <Add
                                    className="m-4"
                                    onClick={() => {
                                        setOpenGroupInviteModal(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end m-4">
                        <button
                            type="button"
                            className="btn-secondary"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button className="btn-primary ml-4" type="submit">
                            Save Group
                        </button>
                    </div>
                </fieldset>
            </form>
            {showToast ? (
                <Overlay className="bg-transparent">
                    <Toast
                        type={ToastTypes.SUCCESS}
                        label="invite sent!"
                        onClose={() => {
                            setShowToast(false);
                        }}
                    />
                </Overlay>
            ) : null}
            {group?.id && openGroupInviteModal ? (
                <Modal>
                    <GroupInviteForm
                        group={group}
                        onCancel={() => setOpenGroupInviteModal(false)}
                        onCompleted={() => {
                            setOpenGroupInviteModal(false);
                            setShowToast(true);
                        }}
                    />
                </Modal>
            ) : null}
        </>
    );
};

export default GroupForm;
